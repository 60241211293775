<template>
  <table id="cestaBasica">
    <div id="imgcestabasica" style="border: 1px solid; background-color: #fff">
      <div style="border: 1px solid; margin: 15px; background-color: #fff">
        <thead>
          <tr>
            <th>
              <img
                style="float: left"
                src="logo.jpg"
                width="75px"
                height="50px"
              />
            </th>
            <th>
              <span style="float: right; padding-right: 5px;"> REF: {{ mesref }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2">SERVICO DE SAUDE DR CANDIDO FERREIRA</td>
          </tr>
          <tr>
            <td colspan="2">C E S T A &nbsp; B Á S I C A</td>
          </tr>
          <tr>
            <td colspan="2">Nome: {{ nome }}</td>
          </tr>
          <tr>
            <td colspan="2">
              Período de retirada da cesta básica é de
              {{ datacesta.data_inicio | formataDataGlobal }} a
              {{ datacesta.data_fim | formataDataGlobal }}<br />
              Local de Retirada: Rua Maria Soares, 225 - Vila
              Industrial-Campinas -SP.<br />
              <b
                >Para a retirada da cesta básica será obrigatório a apresentação
                deste vale e documento com foto.</b
              >
            </td>
          </tr>
          <tr>
            <td colspan="2"><b>HORÁRIO DE RETIRADA</b></td>
          </tr>
          <tr>
            <td colspan="2">
              SEGUNDA A SEXTA-FEIRA - 08:00 AS 17:00 HORAS<br />
              SÁBADO DAS 08h00 ÁS 12h00 <br />
              <b>Telefones para entregas:</b> (19) 3365-1577 / (19) 3365-1477<br />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              A cesta básica não será entregue fora do prazo determinado
              acima.<br />
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <center>
                <img src="assinatura.png" width="150px" height="100px" />
              </center>
            </td>
          </tr>
        </tbody>
      </div>
    </div>
  </table>
</template>

<script>
export default {
  props: ["nome", "mesref", "datacesta"]
};
</script>
<style>
#cestaBasica {
  margin: 0 auto;
}

#cestaBasica td {
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#cestaBasica th {
  padding: 5px 10px;
}

@media screen and (max-width: 450px) {
  #cestaBasica {
    font-size: 8px;
  }
}
</style>
